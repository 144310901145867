<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <v-toolbar color="primary" dark flat short class="px-2">
        <v-toolbar-title>Delete variants</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-12">

        <p>Delete {{ variants ? variants.length : 0 }} variant(s)
          <span class="ml-5">
            <v-progress-circular v-if="isBusy" :size="30" color="grey" indeterminate></v-progress-circular>
          </span>
        </p>

      </v-card-text>

      <v-card-actions class="px-6 pb-8">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-5"
          large
          outlined
          depressed
          @click="cancel()"
        >{{ 'Cancel'}}</v-btn>
        <v-btn
          v-if="variants && variants.length"
          color="warning"
          class="px-5"
          large
          depressed
          @click="start()"
        >Delete</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
/* eslint-disable */
import { inject, ref, watch } from 'vue'

export default {
  components: {
  },
  props: {
    value: Boolean,
    variants: Array,
  },
  setup(props, context) {

    const api = inject('api')

    const dialog = ref(false)

    const isBusy = ref(false)

    watch(() => props.value, val => {
      if (val) {
        isBusy.value = false
      }
      dialog.value = val
    })

    watch(dialog, val => {
      context.emit('input', val)
    })

    function start() {
      isBusy.value = true
      const variantIds = props.variants.map(variant => variant.id)
      const promises = []
      for (let variantId of variantIds) {
        promises.push(api.model('product-variants/:id').delete(variantId))
      }
      Promise.all(promises).then(() => {
        context.emit('done')
        hide()
      }).finally(() => {
        isBusy.value = false
      })
    }

    function cancel() {
      dialog.value = false
    }

    function show() {
      dialog.value = true
    }

    function hide() {
      dialog.value = false
    }

    return {
      dialog,

      start,
      cancel,

      show,
      hide,

      isBusy,
    }
  }}
</script>

<style scoped>

.image {
  height: 200px;
}

.image.bordered {
  border: 1px solid #eeeeee;
}
</style>