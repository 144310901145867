<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Variants
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Search..."
        append-icon="fas fa-search"
        clearable
        clear-icon="fas fa-times"
        single-line
        hide-details
        @keyup="itemSearch()"
        @click:clear="itemSearch()"
      ></v-text-field>
    </v-row>

    <v-data-table
      v-model="variants"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50, 100, 250, 500] }"
      show-select
      :loading="isSearching"
      @click:row="gotoItem"
    >

      <template v-slot:item.sales_price="{ item }">
        {{ formatCurrency(item.sales_price) }}
      </template>

      <template v-slot:item.is_active="{ item }">
        <v-icon :color="item.is_active ? 'secondary' : 'grey'" x-small>fas fa-circle</v-icon>
      </template>

      <template v-slot:footer.prepend>
        <v-btn
          v-if="variants.length"
          icon
          @click="variantsDeleteDialog = true"
        >
          <v-icon>fas fa-trash</v-icon>
        </v-btn>
        <span
          v-if="variants.length"
          class="ml-5"
        >
          {{ variants.length }} selected
        </span>
        <v-btn
          v-if="variants.length"
          icon
          x-small
          @click="clearVariants()"
        ><v-icon color="grey">fas fa-times</v-icon></v-btn>

      </template>

    
    </v-data-table>

    <product-variants-delete-dialog
      v-model="variantsDeleteDialog"
      :variants="variants"
      @done="refreshItems()"
    ></product-variants-delete-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { formatCurrency } from '@/utils'
import ProductVariantsDeleteDialog from '@/components/ProductVariantsDeleteDialog.vue'

export default {
  components: {
    ProductVariantsDeleteDialog,
  },
  setup() {
    const api = inject('api')
    const router = useRouter()

    const itemCollection = api.collection('product-variants', {
      params: {
        expand: 'category,brand',
      },
      filter: {
        query: 'query:description,unique_identifier',
      },
      store: true,
    })

    const { items, filter, totalCount, options, isSearching } = itemCollection

    const headers = [
      { text: 'Code', value: 'unique_identifier', },
      { text: 'Description', value: 'description' },
      { text: 'Sales Price', value: 'sales_price' },
      { text: 'Active', value: 'is_active', align: 'center' },
    ]

    function gotoItem(item) {
      router.push({
        name: 'variant',
        params: { id: item.id }
      })
    }

    const variantsDeleteDialog = ref(false)
    const variants = ref([])

    function refreshItems() {
      itemCollection.search()
      variants.value = []
    }

    function clearVariants() {
      variants.value = []
    }

    const itemSearch = itemCollection.debounceSearch(300)

    return {
      user: api.user,
      headers,
      items,
      filter,
      itemSearch,
      totalCount,
      options,
      isSearching,
      gotoItem,
      formatCurrency,
      variantsDeleteDialog,
      variants,
      clearVariants,
      refreshItems,
    }

  },
}
</script>


<style scoped>

:deep(.v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

</style>
